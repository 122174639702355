/********** ( START BUTTON STYLING ) **********/
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: $main_color;
    background-color: transparent;
    border-color: $main_color;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn {
    position: relative;
    z-index: 99;
    padding: 7px 20px;
    border-radius: 25px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
        -webkit-transform: scale(1.1,1.1);
        -moz-transform: scale(1.1,1.1);
        -ms-transform: scale(1.1,1.1);
        -o-transform: scale(1.1,1.1);
        transform: scale(1.1,1.1);
    }
}
.btn-primary {
    background-color: $main_color;
    border: 1px solid $main_color;
    box-shadow: 0px 0px 27px $main_color;
    &:hover {
        background-color: $main_color;
        border: 1px solid $main_color;
    }
    &:active {
        background-color: $main_color;
        border: 1px solid $main_color;
    }
    &:focus {
        background-color: $main_color;
        border: 1px solid $main_color;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

.btn-secondary {
    background-color: $secondary_color;
    border: 1px solid $secondary_color;
    &:hover {
        background-color: $secondary_color;
        border: 1px solid $secondary_color;
    }
}

/********** ( END BUTTON STYLING ) **********/