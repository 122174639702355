/********** ( START TYPOGRAPHY STYLING )  **********/
p, h1, h2, h3, h4, h5, h6, span, label {
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
}

p {
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

ul li strong {
  color: #2F686C;
}

h2, h3, h4, h5 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  line-height: 47px;
}

h2 span, h3 span, h4 span, h5 span {
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  line-height: 47px;
  word-break: break-word;
}

h1 {
  color: #F4DA00;
  letter-spacing: 10px;
  text-shadow: 0px 3px 16px #000000;
}

h3 {
  color: #ffffff;
  letter-spacing: 6px;
}

.text-fade-in h1 {
  font-size: 50px;
  margin-top: 50px;
}

#home .text-fade-in h1 {
  font-size: 50px;
}

/********** ( END TYPOGRAPHY STYLING )  **********/
/********** ( START NAV STYLING )  **********/
.navbar .navbar-brand {
  width: 200px;
}

.navbar .nav-item .nav-link {
  color: #ffffff;
}

.navbar .nav-item .nav-link:hover {
  color: #F4DA00;
  text-decoration: none;
}

.navbar .nav-item:hover {
  text-decoration: none;
}

.navbar-toggler {
  outline: none;
  border: none;
}

.navbar-toggler:focus {
  outline: none;
  border: none;
}

.navbar-toggler span {
  color: #F4DA00 !important;
  background-image: none !important;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #F4DA00;
}

.navbar-light .navbar-toggler-icon {
  color: #ffffff;
}

.top-nav-social {
  position: absolute;
  top: 10px;
  right: 0;
  padding-right: 39px;
  display: none;
}

.top-nav-social .small-image {
  width: 20px;
}

.top-nav-social a {
  color: #ffffff;
}

.top-nav-social a:hover {
  color: #F4DA00;
}

.active-menu {
  color: #F4DA00 !important;
}

.dropdown-menu {
  background-color: #15243B;
}

.dropdown-menu .dropdown-item {
  color: #ffffff;
  background-color: #15243B;
}

.dropdown-menu .dropdown-item:hover {
  color: #F4DA00;
}

/********** ( END NAV STYLING )  **********/
/********** ( START BUTTON STYLING ) **********/
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #1C75CE;
  background-color: transparent;
  border-color: #1C75CE;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn {
  position: relative;
  z-index: 99;
  padding: 7px 20px;
  border-radius: 25px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.btn-primary {
  background-color: #1C75CE;
  border: 1px solid #1C75CE;
  box-shadow: 0px 0px 27px #1C75CE;
}

.btn-primary:hover {
  background-color: #1C75CE;
  border: 1px solid #1C75CE;
}

.btn-primary:active {
  background-color: #1C75CE;
  border: 1px solid #1C75CE;
}

.btn-primary:focus {
  background-color: #1C75CE;
  border: 1px solid #1C75CE;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  background-color: #2F686C;
  border: 1px solid #2F686C;
}

.btn-secondary:hover {
  background-color: #2F686C;
  border: 1px solid #2F686C;
}

/********** ( END BUTTON STYLING ) **********/
/********** ( START FOOTER STYLING ) **********/
.footer {
  height: auto;
  color: #ffffff;
  background-color: #051226;
  padding-top: 50px;
  font-size: 0.9em;
}

.footer p {
  font-weight: 100;
}

.footer a {
  color: #ffffff;
  font-weight: 100;
}

.footer a:hover {
  color: #F4DA00;
}

.footer strong {
  color: #F4DA00;
  font-weight: bold;
}

.facebook-feed {
  position: relative;
  width: 305px;
  height: 620px;
}

#newsletter {
  margin-top: -239px;
}

.fb_iframe_widget {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 17px;
  border-radius: 100%;
}

.fb_iframe_widget iframe {
  border-radius: 100px;
}

.facebook-feed img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.copyright-footer {
  border-top: 2px solid #ffffff;
}

.copyright {
  color: #ffffff;
  background-color: #051226;
  margin-top: 25px;
  margin-bottom: 25px;
}

.copyright a {
  color: #ffffff;
}

.copyright a:hover {
  color: #F4DA00;
}

/********** ( END FOOTER STYLING ) **********/
/********** ( START OWLSLIDER STYLING ) **********/
.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.owl-carousel {
  position: relative;
  height: 100%;
}

.owl-carousel div:not(.owl-controls) {
  height: 100%;
}

.owl-carousel .slider-text {
  position: absolute;
  bottom: 4em;
  left: 2em;
  width: 20em;
  height: 8em;
}

.owl-prev, .owl-next {
  width: 50px;
  height: 50px !important;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -25px;
  border-radius: 50%;
}

.owl-prev i, .owl-next i {
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: -13%;
  transform: translateX(-50%);
}

.owl-next {
  right: 0;
}

.owl-next i {
  left: 150%;
}

.owl-prev {
  left: 0;
}

.owl-prev i {
  right: 100%;
}

.owl-item {
  width: 100vw;
}

.header-slider .owl-item img {
  height: calc(100vh - 56px);
}

.header-slider {
  margin-top: -60px;
}

.header-image {
  width: 100%;
  height: 100vh !important;
  position: relative;
}

.logo-slider img,
.project-slider img {
  height: auto;
}

.header-img {
  overflow: hidden;
  position: relative;
}

.text-fade-in {
  position: relative;
  z-index: 2;
  width: 100%;
  margin-top: -100px !important;
}

.text-fade-in p {
  color: #ffffff;
}

.text-fade-in.no-style {
  position: unset;
  top: unset;
  left: unset;
  z-index: unset;
  width: unset;
  display: block;
}

.text-fade-in.no-style p {
  color: #ffffff;
}

.scroll-wrapper {
  position: absolute;
  bottom: 20px;
  z-index: 9;
  left: 50%;
  width: 100px;
  height: 128px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.scroll-wrapper .mmouse {
  margin-bottom: -26px;
}

.scroll-wrapper .scroll-text {
  font-size: 14px;
  margin-top: 8px;
}

.owl-dot {
  height: 10px !important;
  width: 10px !important;
  background-color: #ffffff;
  display: inline-block;
  margin-right: 20px;
  border-radius: 25px;
}

.owl-dot.active {
  background-color: #F4DA00;
}

.diensten-slider.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.diensten-slider .owl-dots {
  position: absolute;
  bottom: 20px;
  height: 5% !important;
  left: 50%;
  transform: translateX(-50%);
}

.diensten-slider .owl-next {
  right: 15%;
}

.diensten-slider .owl-prev {
  left: 15%;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh !important;
  width: 100vw;
}

.h-unset {
  height: unset !important;
}

@media (max-width: 1024px) {
  .logo-slider img,
  .project-slider img {
    max-width: 200px !important;
  }
}

@media (max-width: 992px) {
  .text-fade-in {
    top: 22%;
    left: 10%;
    width: 80%;
  }
  .owl-carousel .owl-item img {
    width: 100% !important;
  }
  .header-slider .owl-item img {
    height: 40vh !important;
  }
  .owl-prev, .owl-next {
    width: 30px;
    height: 30px !important;
  }
  .owl-prev i, .owl-next i {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .background-image {
    height: 110vh !important;
  }
  .owl-next {
    right: 7px;
  }
  .owl-prev {
    left: 7px;
  }
}

/********** ( END OWLSLIDER STYLING ) **********/
#404 .bg-404 {
  background-size: cover;
  width: 100vw;
  height: 100vw;
}

/********** ( START MAIN STYLING ) **********/
/********** ( START MAIN STYLING (no classes) ) **********/
html, body {
  overflow-x: hidden;
}

body#home {
  background: url("/assets/images/V1_BB_Website_Achtergrondvisual_Edited.jpg") center fixed no-repeat;
  background-size: cover;
}

body {
  background-color: #040E1D;
}

a {
  color: #000000;
}

a:hover {
  color: #1C75CE;
  text-decoration: none;
}

/********** ( END MAIN STYLING (no classes) ) **********/
/********** ( MAIN STYLING (classes) ) **********/
.white-filter {
  filter: brightness(0) invert(1);
}

.top-of-page {
  margin-top: 90px;
}

.mt-220px {
  margin-top: 220px;
}

.main-color {
  color: #F4DA00;
}

.text-underline {
  text-decoration: underline;
}

.mt--1 {
  margin-top: -1px;
}

.bg-blue {
  background-color: #15243B;
}

.bg-darkblue {
  background-color: #051226;
}

.bg-small-image {
  height: 80vh !important;
}

/********** ( END MAIN STYLING CLASSES ) **********/
/********** ( END MAIN STYLING ) **********/
/********** ( START ABOUT STYLING ) **********/
#about .custom-height {
  display: flex;
  align-items: center;
  background-color: #040E1D;
}

/********** ( END ABOUT STYLING ) **********/
/********** ( START PORTFOLIO STYLING ) **********/
#portfolio .owl-dots {
  display: none;
}

/********** ( END PORTFOLIO STYLING ) **********/
/********** ( START FANCY BOX ) **********/
.fancybox-button {
  background: none !important;
}

.fancybox-thumbs {
  background: #000000 !important;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #fff !important;
}

.fancybox-thumbs__list a:before {
  border: 3px solid #F4DA00 !important;
}

.fancybox-button--zoom {
  display: none !important;
}

.fancybox-progress {
  background-color: #F4DA00 !important;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #F4DA00 !important;
}

.fancybox-infobar span {
  color: #F4DA00 !important;
}

/********** ( END FANCY BOX ) **********/
/********** ( START DIENSTEN STYLING ) **********/
#diensten .img-wrapper {
  position: relative;
}

#diensten .img-wrapper:after {
  content: '';
  position: absolute;
  width: 131px;
  height: 131px;
  background-color: #040E1D;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  top: -13px;
}

#diensten .custom-placing-image {
  width: 31% !important;
}

#diensten .custom-spacing {
  margin-top: -53px !important;
}

/********** ( END DIENSTEN STYLING ) **********/
/********** ( START PROJECTEN STYLING ) **********/
#project .owl-dots {
  display: none;
}

.lightbox-img-bg {
  width: 250px;
  height: 250px !important;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
}

/********** ( END PROJECTEN STYLING ) **********/
/********** ( START CONTACT STYLING ) **********/
#contact .custom-height {
  height: 370px;
  display: flex;
  align-items: center;
  background-color: #040E1D;
}

/********** ( END CONTACT STYLING ) **********/
@supports (-ms-ime-align: auto) {
  #diensten .img-wrapper:after {
    display: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .diensten-slider .item-wrapper {
    height: 101% !important;
  }
  #diensten .img-wrapper::after {
    content: "";
    -webkit-clip-path: none !important;
    clip-path: none !important;
  }
}

/********** ( START MEDIA QUERIES ) **********/
@media (min-width: 350px) {
  .navbar .navbar-brand {
    width: 250px;
  }
}

@media (min-width: 768px) {
  .text-fade-in h1 {
    font-size: 90px;
    margin-top: 0px;
  }
  .bg-small-image {
    height: 40vh !important;
  }
  .top-nav-social {
    display: block;
  }
  #home .text-fade-in h1 {
    font-size: 90px;
  }
  #diensten .custom-placing-image {
    width: 16% !important;
  }
}

@media (min-width: 992px) {
  #diensten .custom-placing-image {
    width: 11% !important;
  }
  #diensten .img-wrapper:after {
    content: '';
    position: absolute;
    width: 141px;
    height: 141px;
    background-color: #040E1D;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    top: -19px;
  }
}

/********** ( END MEDIA QUERIES ) **********/
