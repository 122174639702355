/********** ( START MEDIA QUERIES ) **********/
@media (min-width: 350px) {
    .navbar .navbar-brand {
        width: 250px;
    }

}

@media (min-width: 768px) {
    .text-fade-in h1 {
        font-size: 90px;
        margin-top: 0px;
    }
    .bg-small-image {
        height: 40vh !important;
    }
    .top-nav-social {
        display: block;
    }
    #home .text-fade-in h1 {
        font-size: 90px;
    }
    #diensten {
        .custom-placing-image {
            width: 16% !important;
        }
    }
}

@media (min-width: 992px) {
    #diensten {
        .custom-placing-image {
            width: 11% !important;
        }
        .img-wrapper {
            &:after {
                content: '';
                position: absolute;
                width: 141px;
                height: 141px;
                background-color: #040E1D;
                -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
                top: -19px;
            }
        }
    }
}

@media (min-width: 1200px) {

}

@media (min-width: 1440px) {

}

/********** ( END MEDIA QUERIES ) **********/