/********** ( START FOOTER STYLING ) **********/

.footer{
    height: auto;
    color: #ffffff;
    background-color: #051226;
    padding-top: 50px;
    font-size: 0.9em;
    p {
        font-weight: 100;
    }
    a {
        color: $white;
        font-weight: 100;
        &:hover {
            color: #F4DA00;
        }
    }
    strong {
        color: #F4DA00;
        font-weight: bold;
    }

}
.facebook-feed{
    position: relative;
    width:305px;
    height:620px;
}
#newsletter {
    margin-top: -239px;
}

.fb_iframe_widget {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 17px;
    border-radius: 100%;
}

.fb_iframe_widget iframe{
    border-radius: 100px;
}

.facebook-feed img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    top:0;
    left:0;
    pointer-events: none;
}

.copyright-footer {
    border-top: 2px solid #ffffff;
}

.copyright {
    color: #ffffff;
    background-color: #051226;
    margin-top: 25px;
    margin-bottom: 25px;
    a {
        color: #ffffff;
        &:hover {
            color: #F4DA00;
        }
    }
}


/********** ( END FOOTER STYLING ) **********/