/********** ( START TYPOGRAPHY STYLING )  **********/

p, h1, h2, h3, h4, h5, h6, span, label {
    font-family: $main_font_family;
    color: $white;
}

p {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

ul li strong {
    color: $secondary_color;
}


h2, h3, h4, h5 {
    font-weight: 600;
    font-family: $secondary_font_family !important;
    line-height: 47px;
}

h2 span, h3 span, h4 span, h5 span {
    font-weight: 600;
    font-family: $secondary_font_family !important;
    line-height: 47px;
    word-break: break-word;
}

h1 {
    color: #F4DA00;
    letter-spacing: 10px;
    text-shadow: 0px 3px 16px $black;
}

h3 {
    color: $white;
    letter-spacing: 6px;
}

.text-fade-in h1 {
    font-size: 50px;
    margin-top: 50px;
}

#home .text-fade-in h1 {
    font-size: 50px;
}

/********** ( END TYPOGRAPHY STYLING )  **********/
