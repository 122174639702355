/********** ( START NAV STYLING )  **********/

.navbar {
    .navbar-brand {
        width: 200px;
    }
    .nav-item {
        .nav-link {
            color: $white;
            &:hover {
                color: #F4DA00;
                text-decoration: none;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
}

.navbar-toggler {
    outline: none;
    border: none;
    &:focus {
        outline: none;
        border: none;
    }
    span {
        color: #F4DA00 !important;
        background-image: none !important;
    }
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #F4DA00;
}


.navbar-light .navbar-toggler-icon {
    color: #ffffff;
}

.top-nav-social {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 39px;
    display: none;
    .small-image {
        width: 20px;
    }
    a {
        color: #ffffff;
        &:hover {
            color: #F4DA00;
        }
    }
}
.active-menu {
    color: #F4DA00 !important;
}

.dropdown-menu {
    background-color: #15243B;
    .dropdown-item {
        color: #ffffff;
        background-color: #15243B;
        &:hover {
            color: #F4DA00;
        }
    }
}

/********** ( END NAV STYLING )  **********/