@import "variablen";
@import "typography";
@import "nav";
@import "buttons";
@import "footer";
@import "owlslider";
@import "404";

/********** ( START MAIN STYLING ) **********/
/********** ( START MAIN STYLING (no classes) ) **********/

html, body{
    overflow-x: hidden;
}

body#home {
    background: url("/assets/images/V1_BB_Website_Achtergrondvisual_Edited.jpg") center fixed no-repeat;
    background-size: cover;
}
body {
    background-color: #040E1D;
}

a {
    color: $black;
    &:hover {
        color: $main_color;
        text-decoration: none;
    }
}

/********** ( END MAIN STYLING (no classes) ) **********/
/********** ( MAIN STYLING (classes) ) **********/

.white-filter {
    filter: brightness(0) invert(1);
}

.top-of-page {
    margin-top: 90px;
}

.mt-220px {
    margin-top: 220px;
}

.main-color {
    color: #F4DA00;
}

.text-underline {
    text-decoration: underline;
}

.mt--1 {
    margin-top: -1px;
}

.bg-blue {
    background-color: #15243B;
}


.bg-darkblue {
    background-color: #051226;
}

.bg-small-image {
    height: 80vh !important;
}

/********** ( END MAIN STYLING CLASSES ) **********/
/********** ( END MAIN STYLING ) **********/
/********** ( START ABOUT STYLING ) **********/
#about .custom-height {
    display: flex;
    align-items: center;
    background-color: #040E1D;
}
/********** ( END ABOUT STYLING ) **********/
/********** ( START PORTFOLIO STYLING ) **********/
#portfolio .owl-dots {
    display: none;
}
/********** ( END PORTFOLIO STYLING ) **********/
/********** ( START FANCY BOX ) **********/
.fancybox-button{
    background: none!important;
}

.fancybox-thumbs{
    background: #000000!important;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited{
    color: #fff!important;
}

.fancybox-thumbs__list a:before{
    border: 3px solid #F4DA00!important;
}

.fancybox-button--zoom{
    display: none!important;
}

.fancybox-progress{
    background-color: #F4DA00!important;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited{
    color: #F4DA00!important;
}
.fancybox-infobar {
    span {
        color: #F4DA00 !important;
    }
}
/********** ( END FANCY BOX ) **********/
/********** ( START DIENSTEN STYLING ) **********/
#diensten {
    .img-wrapper {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 131px;
            height: 131px;
            background-color: #040E1D;
            -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            top: -13px;
        }
    }
    .custom-placing-image {
        width: 31% !important;
    }
    .custom-spacing {
        margin-top: -53px !important;
    }
}
/********** ( END DIENSTEN STYLING ) **********/
/********** ( START PROJECTEN STYLING ) **********/
#project .owl-dots {
    display: none;
}
.lightbox-img-bg {
    width: 250px;
    height: 250px!important;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
}
/********** ( END PROJECTEN STYLING ) **********/
/********** ( START CONTACT STYLING ) **********/
#contact .custom-height {
    height: 370px;
    display: flex;
    align-items: center;
    background-color: #040E1D;
}
/********** ( END CONTACT STYLING ) **********/

//this one has to be on the last line
@import "internet";
@import "responsive";