/********** ( START OWLSLIDER STYLING ) **********/

.owl-carousel .owl-stage {
    display: flex;
    align-items: center;
}

.owl-carousel {
    position: relative;
    height: 100%;

    div:not(.owl-controls) {
        height: 100%;
    }
    .slider-text {
        position: absolute;
        bottom: 4em;
        left: 2em;
        width: 20em;
        height: 8em;
    }
}

.owl-prev, .owl-next {
    width: 50px;
    height: 50px !important;
    position: absolute;
    top: 50%;
    display: block;
    margin-top: -25px;
    border-radius: 50%;
    i {
        color: #fff;
        font-size: 50px;
        position: absolute;
        top: -13%;

        transform: translateX(-50%);
    }
}
.owl-next {
    right: 0;
    i {
        left: 150%;
    }
}

.owl-prev {
    left: 0;
    i {
        right: 100%;
    }
}

.owl-item {
    width: 100vw;
}

.header-slider .owl-item img {
    height: calc(100vh - 56px);
}

.header-slider {
    margin-top: -60px;
}

.header-image {
    width: 100%;
    height: 100vh !important;
    position: relative;
}

.logo-slider,
.project-slider {
    img {
        height: auto;
    }
}

.header-img {
    overflow: hidden;
    position: relative;
}

.text-fade-in {
    position: relative;
    z-index: 2;
    width: 100%;
    margin-top: -100px !important;
    p {
        color: #ffffff;
    }
}

.text-fade-in.no-style {
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    width: unset;
    display: block;
    p {
        color: #ffffff;
    }
}

.scroll-wrapper {
    position: absolute;
    bottom: 20px;
    z-index: 9;
    left: 50%;
    width: 100px;
    height: 128px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    .mmouse {
        margin-bottom: -26px;
    }
    .scroll-text {
        font-size: 14px;
        margin-top: 8px;
    }
}
.owl-dot {
    height: 10px !important;
    width: 10px !important;
    background-color: #ffffff;
    display: inline-block;
    margin-right: 20px;
    border-radius: 25px;
    &.active{
        background-color: #F4DA00;
    }
}
.diensten-slider.owl-carousel .owl-stage-outer {
    overflow: visible;
}
.diensten-slider{
    .owl-dots {
        position: absolute;
        bottom: 20px;
        height: 5% !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .owl-next {
        right: 15%;
    }
    .owl-prev {
        left: 15%;
    }
}
.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 80vh !important;
    width: 100vw;
}

.h-unset {
    height: unset !important;
}

@media (max-width: 1024px) {
    .logo-slider,
    .project-slider {
        img {
            max-width: 200px !important;
        }
    }
}

@media (max-width: 992px) {
    .text-fade-in {
        top: 22%;
        left: 10%;
        width: 80%;
    }
    .owl-carousel {
        .owl-item {
            img {
                width: 100% !important;
            }
        }
    }
    .header-slider {
        .owl-item {
            img {
                height: 40vh !important;
            }
        }
    }
    .owl-prev, .owl-next {
        width: 30px;
        height: 30px !important;
        i {
            font-size: 30px;
        }
    }

}

@media (max-width: 576px) {
    .background-image {
        height: 110vh !important;
    }
    .owl-next {
        right: 7px;
    }
    .owl-prev {
        left: 7px;
    }
}

/********** ( END OWLSLIDER STYLING ) **********/
