@supports (-ms-ime-align:auto) {
    #diensten {
        .img-wrapper {
            &:after {
                display: none;
            }
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .diensten-slider {
        .item-wrapper {
            height: 101% !important;
        }
    }
    #diensten .img-wrapper::after {
        content: "";
        -webkit-clip-path: none !important;
        clip-path: none !important;
    }

}